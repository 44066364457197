import { CognitoJwtVerifier } from 'aws-jwt-verify';

export const verifyAccessToken = async (): Promise<boolean> => {
    const accessToken = typeof window !== 'undefined' ? localStorage.getItem("accessToken") : null;

    if (!accessToken) {
        console.log("No accessToken exists");
        return false;
    }

    const verifier = CognitoJwtVerifier.create({
        userPoolId: "eu-west-1_xM5RFovNe",
        tokenUse: "access",
        clientId: "203e1n3md76t3mud66igceti2i",
    });

    try {
        const payload = await verifier.verify(accessToken);
        console.log("Token is valid. Payload:", payload);
        return true;
    } catch (error) {
        console.log("Token not valid!", error);
        return false;
    }
}

export default verifyAccessToken;