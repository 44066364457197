// Function to retrieve idToken from local storage
export function getIdToken() {
    const idToken = localStorage.getItem("idToken");
  
    if (!idToken) {
      console.error('idToken is not valid');
      return null;
    }
  
    return idToken;
  }

  export default getIdToken;