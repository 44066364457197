import React, { useState, ChangeEvent } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Avatar,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import DownloadedVideosTable from "./DownloadedVideosTable";
import DownloadLinkField from "./DownloadLinkField"; // Added this line
import TorrentSearch from "./TorrentSearch";
import TorrentTable from "./TorrentTable";

interface TabPanelProps {
  children: React.ReactNode;
  value: number;
  index: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export const Dashboardv2 = () => {
  const [value, setValue] = useState(0);
  const [torrents, setTorrents] = useState<any[]>([]);
    
  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <AppBar position="static" color="inherit" style={{ backgroundColor: '#000' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" style={{ color: '#fff' }}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1, color: '#fff' }}>
            Menu
          </Typography>
          <IconButton color="inherit" style={{ color: '#fff' }}>
            <AccountCircle />
          </IconButton>
          <Button color="inherit" style={{ color: '#fff' }}>Logout</Button>
        </Toolbar>
      </AppBar>
      <header style={{
        height: '200px',
        background: 'linear-gradient(270deg, #ff0000, #00ff00, #0000ff)',
        backgroundSize: '600% 600%',
        animation: 'RGBRotation 10s ease infinite',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <Typography variant="h1" component="div" style={{ color: '#fff', fontWeight: 'bold' }}>
          Not-Seedr
        </Typography>
      </header>
      <DownloadLinkField />
      <Tabs value={value} onChange={handleChange} centered>
        <Tab label="My Downloads" />
        <Tab label="Download Torrent" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Box
          sx={{
            overflow: 'auto',
            height: '80vh',
            width: '100%',
            maxWidth: '80%',
            borderRadius: '4px',
            padding: '16px',
            margin: 'auto',
            '@media (max-width:600px)': {
              width: '100%',
              maxWidth: '100%', // Make the box take full width on small screens
              height: 'auto', // Adjust the height as needed
              padding: '8px', // Reduce padding on small screens
            },
            '@media (min-width:601px) and (max-width:1024px)': {
              // Apply different styles for tablets or medium-sized screens
              maxWidth: '90%',
              padding: '12px',
            },
          }}
        >
          <DownloadedVideosTable />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TorrentSearch setTorrents={setTorrents} />
        <TorrentTable torrents={torrents} />{" "}
      </TabPanel>
    </div>
  );
};
