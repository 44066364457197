import { InvokeCommand, InvokeCommandOutput } from "@aws-sdk/client-lambda";
import getIdToken from "../services/getIdToken";
import { getCognitoProvider, getLambdaClient } from "../services/lambdaClient";

async function torrentFetchAPI(query: string) {
    
    const idToken = getIdToken() ?? "";
    const cognitoProvider = getCognitoProvider(idToken);
    let lambda = getLambdaClient(cognitoProvider); // Use setLambda here

    const command = new InvokeCommand({
        FunctionName: "getTorrLinks2",
        Payload: JSON.stringify({
            queryStringParameters: {
                query: query,
            },
        }),
    });

    const response: InvokeCommandOutput = await lambda?.send(command);
    const payLoad = response.Payload;
    const asciiDecoder = new TextDecoder('ascii'); // This needs updated, text looks funky in returned results
    const decodedData = asciiDecoder.decode(payLoad);
    console.log("Payload is: " + decodedData)

    return decodedData;
}

export default torrentFetchAPI;