import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js'
import userpool from '../userpool'

export const authenticate = (Email: string, Password: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        const cognitoUser = new CognitoUser({
            Username: Email,
            Pool: userpool
        });

        const authenticationDetails = new AuthenticationDetails({
            Username: Email,
            Password
        });

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (result) => {
                const idToken = result.getIdToken().getJwtToken();
                const accessToken = result.getAccessToken().getJwtToken();
                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('idToken', idToken);
                resolve(idToken)
            },
            onFailure: (error) => {
                reject(error);
            }
        })
    });
};

export default authenticate;