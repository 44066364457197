import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';

const TorrentRow: React.FC<{ torrent: any }> = ({ torrent }) => {
    const handleClick = (magnet: string) => {
        navigator.clipboard.writeText(magnet);
        console.log(magnet);
    };

    return (
        <TableRow key={torrent.Name}>
            {Object.keys(torrent).map(key => key !== 'Magnet' ? <TableCell>{torrent[key]}</TableCell> : null)}
            <TableCell>
                <Button variant="contained" color="primary" onClick={() => handleClick(torrent.Magnet)}>Copy Magnet Link</Button>
            </TableCell>
        </TableRow>
    );
}

export const TorrentTable: React.FC<{ torrents: any[] }> = ({ torrents }) => {
    const headers = ['Name', 'Size', 'Date Uploaded', 'Seeders', 'Leechers', 'Magnet'];

    return (
        <TableContainer component={Paper}>
            <Table className='torrent-table'>
                <TableHead>
                    <TableRow className="torrent-table-header">
                        {headers.map(header => <TableCell className="torrent-table-header-cell">{header}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {torrents.map(torrent => <TorrentRow torrent={torrent} />)}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default TorrentTable;
