import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { LambdaClient } from "@aws-sdk/client-lambda";

const client = new CognitoIdentityClient({ region: "eu-west-1" });

export function getCognitoProvider(idToken: string) {
  const client = new CognitoIdentityClient({ region: "eu-west-1" });
  return fromCognitoIdentityPool({
    client,
    identityPoolId: "eu-west-1:1418a9b1-4f7f-45e5-a085-256f46475be0",
    logins: {
      "cognito-idp.eu-west-1.amazonaws.com/eu-west-1_xM5RFovNe": idToken || "",
    },
  });
}

export function getLambdaClient(credentials: any) {
  return new LambdaClient({
    region: "eu-west-1",
    credentials: credentials,
  });
}