import { useState, useEffect } from "react";
import { LambdaClient, InvokeCommand } from "@aws-sdk/client-lambda";
import getIdToken from "../services/getIdToken";
import { getCognitoProvider, getLambdaClient } from "../services/lambdaClient";

export const DownloadLinkField = () => {
  const [torrentLink, updateTorrentLink] = useState("");
  const [lambda, setLambda] = useState<LambdaClient | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [isDisabled, setDisabled] = useState(false);

  useEffect(() => {
    const idToken = getIdToken() ?? "";
    const cognitoProvider = getCognitoProvider(idToken);
    setLambda(getLambdaClient(cognitoProvider));
  }, []);

  const handleDownloadLink = () => {
    if (!lambda) return;

    setLoading(true);
    setDisabled(true);

    const command = new InvokeCommand({
      FunctionName: "downloadMagnet",
      Payload: JSON.stringify({
        queryStringParameters: {
          torrentLink: torrentLink,
        },
      }),
    });

    lambda
      .send(command)
      .then((data) => {
        console.log(data);
        updateTorrentLink("");
        setLoading(false);
        setDisabled(false);
      })
      .catch((err) => {
        console.log(err, err.stack);
        setLoading(false);
        setDisabled(false);
      });
  };

  return (
    <div className="searchContainer">
      <input
        className="searchBox"
        value={torrentLink}
        type="text"
        placeholder="Add Magnet Link Here"
        onChange={(event) => updateTorrentLink(event.target.value)}
        disabled={isDisabled}
      />
      {isLoading ? (
        <button className="searchButton processingButton" disabled>
          Processing...
        </button>
      ) : (
        <button className="searchButton" onClick={handleDownloadLink}>
          Download Link
        </button>
      )}
    </div>
  );
};

export default DownloadLinkField;
