import React, { useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogContent, LinearProgress } from '@mui/material';

interface Video {
  videoLibraryId: number;
  guid: string;
  title: string;
  dateUploaded: string;
  views: number;
  isPublic: boolean;
  length: number;
  status: number;
  framerate: number;
  rotation: number;
  width: number;
  height: number;
  availableResolutions: string;
  thumbnailCount: number;
  encodeProgress: number;
  storageSize: number;
  captions: any[];
  hasMP4Fallback: boolean;
  collectionId: string;
  thumbnailFileName: string;
  averageWatchTime: number;
  totalWatchTime: number;
  category: string;
  chapters: any[];
  moments: any[];
  metaTags: any[];
  transcodingMessages: any[];
}

interface VideoListResponse {
  totalItems: number;
  currentPage: number;
  itemsPerPage: number;
  items: Video[];
}


export const DownloadedVideosTable = () => {

  const [videoList, setVideoList] = useState<Video[]>([]);
  const [iframeSrc, setIframeSrc] = useState<string | null>(null);
  const [open, setOpen] = useState(false);

  const url = 'https://video.bunnycdn.com/library/188360/videos';

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      AccessKey: 'a7d92992-80d7-4916-937d24ee0fdf-a6f4-431e'
    }
  };

  useEffect(() => {
    const fetchVideos = () => {
      fetch(url, options)
        .then(res => res.json())
        .then((json: VideoListResponse) => {
          setVideoList(json.items);
        })
        .catch(err => console.error('error:' + err));
    };

    fetchVideos(); // Fetch immediately on component mount
    const intervalId = setInterval(fetchVideos, 5000); // Fetch every 5 seconds

    return () => {
      clearInterval(intervalId); // Clean up on component unmount
    };
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleButtonClick = (video: Video) => {
    console.log(`Button clicked for video: ${video.title}`);
    setIframeSrc(`https://iframe.mediadelivery.net/embed/${video.videoLibraryId}/${video.guid}?autoplay=false&loop=false&muted=false&preload=true&responsive=true`);
    handleOpen();
  };

  return (
    <div>
      <TableContainer component={Paper} style={{maxWidth: '100%', overflowX: 'auto'}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell align="right">Transcoding (%)</TableCell>
              <TableCell align="right">Transcoding Messages</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {videoList.map((video, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {video.title}
                </TableCell>
                <TableCell align="right">
                  <LinearProgress variant="determinate" value={video.encodeProgress} />
                </TableCell>
                <TableCell align="right">
                  {video.encodeProgress < 30 ? (
                    <span>Conversion in progress</span>
                  ) : (
                    <Button 
                      variant="contained" 
                      color={video.encodeProgress < 100 ? "secondary" : "primary"} 
                      onClick={() => handleButtonClick(video)}
                      style={video.encodeProgress < 100 ? {animation: 'pulse 2s infinite'} : {}}
                    >
                      {video.encodeProgress < 100 ? 'Downloading' : 'Stream Now'}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="video-player-dialog"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogContent>
          {iframeSrc && (
            <div style={{ 
              position: 'relative', 
              overflow: 'hidden', 
              paddingTop: '56.25%' /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
            }}>
              <iframe 
                src={iframeSrc} 
                title="Video Player" 
                allowFullScreen 
                style={{ 
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: '100%', 
                  height: '100%' 
                }} 
              />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DownloadedVideosTable;
