import { CognitoUserPool } from 'amazon-cognito-identity-js'

interface PoolData {
  UserPoolId: string;
  ClientId: string;
}

const poolData: PoolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID as string,
  ClientId: process.env.REACT_APP_CLIENT_ID as string
}

export default new CognitoUserPool(poolData)