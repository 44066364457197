import React, { useState } from 'react';
import torrentFetchAPI from '../services/torrentSearchService';

function TorrentSearch({ setTorrents }: { setTorrents: React.Dispatch<React.SetStateAction<any[]>> }) {
    const [query, setQuery] = useState<string>('');

    const handleSubmit = (event: React.MouseEvent) => {
        event.preventDefault();
        torrentFetchAPI(query).then(response => {
            const torrents = JSON.parse(response);
            setTorrents(torrents);
        });
    };

    return (
        <div className="searchContainer">
            <input
                className="searchBox"
                placeholder='Torrent Search'
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
            />
            <button className="searchButton" onClick={handleSubmit}>Search For Torrent</button>
        </div>
    );
}

export default TorrentSearch;