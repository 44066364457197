import "./App.css";
import { useState, useContext, useEffect } from "react";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import { AuthProvider } from "./pages/AuthProvider";
import verifyAccessToken from "./services/tokenVerifier";
import { Dashboardv2 } from "./pages/Dashboardv2";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuthentication = async () => {
      const isAuthenticated = await verifyAccessToken();
      setIsAuthenticated(isAuthenticated);
    };

    checkAuthentication();
  }, []);

  return (
    <AuthProvider>
      {isAuthenticated ? <Dashboardv2 /> : <Login setIsAuthenticated={setIsAuthenticated} />}
    </AuthProvider>
  );
}

export default App;
